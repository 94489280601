import React from "react"
import global from "../../global";
import {Space, Drawer, Button, Form, Input, Select, DatePicker} from "antd";
import {useDispatch, useSelector} from "react-redux";

const OrderPay = (props: {messageApi: any }) => {
  const dispatch = useDispatch();
  const order = useSelector((state: any) => state.order)
  const newForm = Form.useForm()[0]

  const onNewClose = () => {
    dispatch.order.setData({payOpen: false})
  }

  const onNewSave = () => {
    newForm.submit()
  }

  /**
   *  @NotNull(message = "订单编号不能为空")
   *   private Integer orderId;
   *   @NotNull(message = "支付金额不能为空")
   *   private Integer amount;
   *   @NotNull(message = "支付流水号不能为空")
   *   private String payNo;
   *   @NotNull(message = "支付方式不能为空")
   *   private PayType payType;
   *   @NotNull(message = "支付备注不能为空")
   *   private String memo;
   */
  const onNewFinish = () => {
    //提交接口
    let data = {
      orderId: order.info.id,
      payNo: newForm.getFieldValue('payNo'),
      amount: global.getPenny(Number(newForm.getFieldValue('amount'))),
      payType: newForm.getFieldValue('payType'),
      payTime: newForm.getFieldValue('payTime').format('YYYY-MM-DD'),
      memo: newForm.getFieldValue('memo')
    }
    //提交订单
    dispatch.pay.add(data).then((res: any) => {
      if (res.code !== 0) {
        props.messageApi.error(res.msg)
        return;
      }
      props.messageApi.success('订单支付成功')
      dispatch.order.setData({selectedRows: [], selectedRowKeys: []})
      dispatch.order.page()
      dispatch.order.setData({payOpen: false})
    })
  }

  /**
   *   WEPAY("微信支付"),
   *   ALIPAY("支付宝支付"),
   *   CASHPAY("现金支付"),
   *   CARDPAY("刷卡支付"),
   *   TRANSFERPAY("转账支付"),
   *   OTHER("其他");
   */
  const payTypeList = [
    {label: '微信支付', value: 'WEPAY'},
    {label: '支付宝支付', value: 'ALIPAY'},
    {label: '现金支付', value: 'CASHPAY'},
    {label: '刷卡支付', value: 'CARDPAY'},
    {label: '转账支付', value: 'TRANSFERPAY'},
    {label: '其他', value: 'OTHER'},
  ]

  return (
    <Drawer
      title="手动支付"
      width={500}
      onClose={onNewClose}
      open={order.payOpen}
      extra={
        <Space>
          <Button onClick={onNewClose}>取消</Button>
          <Button onClick={onNewSave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={newForm} onFinish={onNewFinish} layout="vertical" initialValues={{memo: '协会手动支付'}}>
        <Form.Item name="payNo" label="付款单号" rules={[{required: true, message: "输入付款单号"}]}>
          <Input placeholder="输入付款单号"/>
        </Form.Item>
        <Form.Item name="amount" label="实付金额" rules={[{required: true, message: "输入实付金额"}]}>
          <Input placeholder="输入实付金额"/>
        </Form.Item>
        <Form.Item name="payType" label="付款方式" rules={[{required: true, message: "选择付款方式"}]}>
          <Select options={payTypeList} placeholder="选择付款方式"/>
        </Form.Item>
        <Form.Item name="payTime" label="付款日期" rules={[{required: true, message: "选择付款日期"}]}>
          <DatePicker placeholder="选择付款日期" format="YYYY-MM-DD"/>
        </Form.Item>
        <Form.Item name="memo" label="备注" rules={[{required: false},]}>
          <Input.TextArea rows={4} placeholder="输入备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default OrderPay