import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const company = createModel<RootModel>()({
  state: {
    info: {},
    request: {
      keyword: "",
      pageNum: 1,
      pageSize: 20,
      companyType: '',
    },
    data: {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 查询公司
     *
     * @param payload
     * @param rootState
     */
    async search(payload, rootState) {
      let res = await request.get("/admin/v1/company/search/" + payload)
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res;
    },

    /**
     * 分页显示
     */
    async page(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/company/page", state.company.request)
      if (res.code === 0) {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.company.setData({data: res.data})
      }
      return res
    },

    /**
     * 添加
     *
     * @param payload
     * @param state
     */
    async add(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/company", payload)
      return res
    },

    /**
     * 修改
     *
     * @param payload
     * @param state
     */
    async modify(payload, state): Promise<any> {
      let res = await request.put("/admin/v1/company", payload)
      return res
    },

    /**
     * 查询公司列表
     *
     * @param payload
     * @param state
     */
    async list(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/company/list", payload)
      if (res.code !== 0) {
        console.error(res.msg)
      }
      return res
    }
  }),
})