import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const userModule = createModel<RootModel>()({
  state: {
    info: {},
    request: {
      keyword: "",
      pageNum: 1,
      pageSize: 20,
    },
    data: {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 分页显示
     */
    async page(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/userModule/page", state.userModule.request)
      if (res.code === 0) {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.userModule.setData({data: res.data})
      }
      return res
    },

    /**
     * 添加
     *
     * @param payload
     * @param state
     */
    async add(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/userModule", payload)
      return res
    },

    /**
     * 修改
     *
     * @param payload
     * @param state
     */
    async modify(payload, state): Promise<any> {
      let res = await request.put("/admin/v1/userModule", payload)
      return res
    },

    /**
     * 删除
     */
    async delete(payload, state): Promise<any> {
      let res = await request.delete("/admin/v1/userModule/" + payload.id)
      return res
    },
  }),
})