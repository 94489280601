import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

/**
 * 机构接触
 */
export const schoolTouch = createModel<RootModel>()({
  state: {
    info: {},
    list: [],
  },
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    /**
     * 列表
     *
     * @param payload
     * @param state
     * @returns
     */
    async list(payload, state): Promise<any> {
      let res = await request.get("/admin/v1/schoolTouch/list/" + payload.schoolId)
      if (res.code === 0) {
        res.data.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.schoolTouch.setData({list: res.data})
      }
      return res
    },

    /**
     * 添加
     *
     * @param payload
     * @param state
     * @returns
     */
    async add(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/schoolTouch", payload)
      return res
    },

    /**
     * 修改
     *
     * @param payload
     * @param state
     * @returns
     */
    async modify(payload, state): Promise<any> {
      let res = await request.put("/admin/v1/schoolTouch", payload)
      return res
    },
  })
});