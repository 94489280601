import {Descriptions, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";

/**
 *   id: number;
 *   orderId: number;
 *   companyName: string;
 *   payNo: string;
 *   payType: string;
 *   payTypeName: string;
 *   payId: string;
 *   amount: number;
 *   payTime: string;
 *   memo: string;
 *   modified: string;
 *   created: string;
 * @constructor
 */
const PayInfo = () => {
  const pay = useSelector((state: any) => state.pay)
  const dispatch = useDispatch()

  return (
    <Drawer
      title="查看计划详情"
      width={500}
      open={pay.openInfo}
      onClose={() => dispatch.pay.setData({openInfo: false})}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="指导单位">{pay.info?.companyName}</Descriptions.Item>
        <Descriptions.Item label="支付单号">{pay.info?.payNo}</Descriptions.Item>
        <Descriptions.Item label="支付方式">{pay.info?.payTypeName}</Descriptions.Item>
        <Descriptions.Item label="支付金额">¥ {pay.info?.amount/100}</Descriptions.Item>
        <Descriptions.Item label="支付时间">{pay.info?.payTime}</Descriptions.Item>
        <Descriptions.Item label="备注">{pay.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{pay.info?.created}</Descriptions.Item>
        <Descriptions.Item label="更新时间">{pay.info?.modified}</Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default PayInfo