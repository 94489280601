import React, {useEffect, useState} from "react"
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  Layout,
  message,
  Select,
  Space,
  Switch,
  Table,
  Tag
} from "antd"
import type {ColumnsType} from "antd/es/table"
import {useDispatch, useSelector} from "react-redux"
import dayjs from "dayjs";
import "../../global";

const {Search} = Input

/**
 * 定义companyType
 * @constructor
 */
interface CompanyType {
  id: number
  companyType: string
  companyTypeName: string
  parent: string
  name: string
  address: string
  merchantName: string
  regNum: string
  licenceCopyKey: string
  legalPerson: string
  idCardCopyKey: string
  idCardNationalKey: string
  idCardName: string
  idCardNumber: string
  idCardAddress: string
  cardPeriodBegin: string
  cardPeriodEnd: string
  linkman: string
  mobile: string
  province: string
  city: string
  enabled: boolean
  expireDate: string
  discount: number
  itemTypes: string[]
  memo: string
  modified: string
  created: string
}

//单位类型
const companyTypes = [
  {value: 'PROVINCE', label: '省级指导单位'},
  {value: 'REGION', label: '区级指导单位'},
  {value: 'TEST_CENTRE', label: '考试中心'},
]

const CompanyTable: React.FC = () => {
  const [infoOpen, setInfoOpen] = useState(false)
  const [info, setInfo] = useState<CompanyType>({} as CompanyType)

  const [newOpen, setNewOpen] = useState(false)
  const [newForm] = Form.useForm()

  const [modifyOpen, setModifyOpen] = useState(false)
  const [modifyForm] = Form.useForm()

  const company = useSelector((state: any) => state.company)
  const dispatch = useDispatch()

  const [messageApi, contextMessageHolder] = message.useMessage()

  const columns: ColumnsType<CompanyType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "状态",
      dataIndex: "enabled",
      key: "enabled",
      render: (text: any, record: any) => {
        return text ? <Tag color="green">正常</Tag> : <Tag color="red">无效</Tag>
      }
    },
    {
      title: "单位类型",
      dataIndex: "companyTypeName",
      key: "companyTypeName",
    },
    {
      title: "折扣率",
      dataIndex: "discount",
      key: "discount",
      render: (text: any, record: any) => {
        return record.discount + '%'
      }
    },
    {
      title: "联系人",
      dataIndex: "linkman",
      key: "linkman",
    },
    {
      title: "手机号码",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => {

        return (
          <>
            <Button type="link" size={"small"} onClick={() => handleInfo(record)}>
              查看
            </Button>
            {
              record.id !== 1 && (
                <Button type="link" size="small" onClick={() => handleModify(record)}>
                  修改
                </Button>
              )
            }
          </>
        )
      },
    },
  ]

  const onSearch = (value: string) => {
    let request = company.request
    request.keyword = value
    request.pageNum = 1
    request.companyType = companyType
    dispatch.company.setData({request})
    dispatch.company.page()
  }

  const handleModify = (record: any) => {
    setModifyOpen(true)
    modifyForm.setFieldsValue({...record, expireDate: dayjs(record.expireDate), enabled: (record.enabled) ? 1 : 0})
  }

  const handleInfo = (record: any) => {
    setInfo({...record});
    setInfoOpen(true)
  }

  const onNewClose = () => {
    setNewOpen(false)
  }

  const onNewSave = () => {
    newForm.submit()
  }

  const onNewFinish = (values: any) => {
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.company.add(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("新增成功")
        dispatch.company.page()
        setNewOpen(false)
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  const onModifyClose = () => {
    setModifyOpen(false)
  }

  const onModifySave = () => {
    modifyForm.submit()
  }

  const onModifyFinish = (values: any) => {
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.company.modify(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("修改成功")
        setModifyOpen(false)
        dispatch.company.page()
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  useEffect(() => {
    dispatch.company.page()
  }, [company.request.keyword, company.request.pageNum, dispatch.company])

  const [parentOptions, setParentOptions] = useState<{ value: string }[]>([]);
  const getParentValues = (searchText: string) => {
    if (!searchText) return;
    dispatch.company.search(searchText).then((res: any) => {
      if (res.code > 0) return;
      setParentOptions(res.data);
    })
  }

  const [companyType, setCompanyType] = useState('')
  const [itemTypeOptions, setItemTypeOptions] = useState<{ label: string, value: string }[]>([]);

  //读取项目类型列表
  useEffect(() => {
    dispatch.itemType.list().then((res: any) => {
      if (res.code > 0) return;
      setItemTypeOptions(res.data.map((item: any) => {
        return {label: item.name, value: item.value}
      }));
    })
  }, [dispatch.itemType])

  return (
    <>
      {contextMessageHolder}
      <Layout>
        <Space>
          <Select onChange={(text: string) => setCompanyType(text)} options={companyTypes} placeholder="选择单位类型"/>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
          <Button type="primary" onClick={() => setNewOpen(true)} style={{backgroundColor: "#339933"}}>
            新建
          </Button>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={company.data.list}
            pagination={{
              pageSize: company.data.pageSize, // 每页显示的条数
              total: company.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...company.request, pageNum: page, pageSize}
                dispatch.company.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <Drawer
        title="查看合作单位"
        width={500}
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        style={{paddingBottom: 80}}
      >
        <Descriptions title="" column={1}>
          <Descriptions.Item label="编号">{info?.id}</Descriptions.Item>
          <Descriptions.Item label="上级单位">{info?.parent}</Descriptions.Item>
          <Descriptions.Item label="单位类型">{info?.companyTypeName}</Descriptions.Item>
          <Descriptions.Item label="名称">{info?.name}</Descriptions.Item>
          <Descriptions.Item label="地址">{info?.address}</Descriptions.Item>
          <Descriptions.Item label="有效状态">{info?.enabled ? '正常' : '无效'}</Descriptions.Item>
          <Descriptions.Item label="到期日期">{info?.expireDate}</Descriptions.Item>
          <Descriptions.Item label="单位名称">{info?.merchantName}</Descriptions.Item>
          <Descriptions.Item label="执照编号">{info?.regNum}</Descriptions.Item>
          <Descriptions.Item label="联系人">{info?.linkman}</Descriptions.Item>
          <Descriptions.Item label="手机号码">{info?.mobile}</Descriptions.Item>
          <Descriptions.Item label="折扣率">{info?.discount}%</Descriptions.Item>
          <Descriptions.Item label="运营项目">
            {info?.itemTypes?.map((item: string) => {
              return itemTypeOptions.find((type) => type.value === item)?.label
            }).map((s) => {
              return <Tag key={s} color="blue">{s}</Tag>
            })}
          </Descriptions.Item>
          <Descriptions.Item label="备注">{info?.memo}</Descriptions.Item>
          <Descriptions.Item label="新增时间">{info?.created}</Descriptions.Item>
        </Descriptions>
      </Drawer>

      <Drawer
        title="新增合作单位"
        width={500}
        onClose={onNewClose}
        open={newOpen}
        style={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onNewClose}>取消</Button>
            <Button onClick={onNewSave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={newForm} onFinish={onNewFinish} layout="vertical">
          <Form.Item name="companyType" label="单位类型" rules={[{required: true, message: "选择单位类型"}]}>
            <Select options={companyTypes} placeholder="选择单位类型"/>
          </Form.Item>
          <Form.Item name="parent" label="选择上级单位" rules={[{required: true, message: "选择上级单位"}]}>
            <AutoComplete
              options={parentOptions}
              onSearch={(text) => getParentValues(text)}
              placeholder="选择上级单位"/>
          </Form.Item>
          <Form.Item name="name" label="名称-<不能重复>" rules={[{required: true, message: "输入名称"}]}>
            <Input placeholder="输入名称"/>
          </Form.Item>
          <Form.Item name="address" label="地址" rules={[{required: true, message: "输入地址"}]}>
            <Input placeholder="输入地址"/>
          </Form.Item>
          <Form.Item name="merchantName" label="营业执照公司名称"
                     rules={[{required: true, message: "输入营业执照公司名称"}]}>
            <Input placeholder="输入营业执照公司名称"/>
          </Form.Item>
          <Form.Item name="regNum" label="执照编号" rules={[{required: true, message: "输入执照编号"}]}>
            <Input placeholder="输入执照编号"/>
          </Form.Item>
          <Form.Item name="linkman" label="联系人" rules={[{required: true, message: "输入联系人"}]}>
            <Input placeholder="输入联系人"/>
          </Form.Item>
          <Form.Item name="mobile" label="手机号码" rules={[{required: true, message: "输入手机号码"}]}>
            <Input placeholder="输入手机号码"/>
          </Form.Item>
          <Form.Item name="expireDate" label="有效期" rules={[{required: true, message: "输入有效期"}]}>
            <DatePicker placeholder="选择有效期" format="YYYY-MM-DD"/>
          </Form.Item>
          <Form.Item name="itemTypes" label="运营项目">
            <Checkbox.Group options={itemTypeOptions} defaultValue={['HOSTING']}/>
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        title="修改合作单位"
        width={500}
        onClose={onModifyClose}
        open={modifyOpen}
        style={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onModifyClose}>取消</Button>
            <Button onClick={onModifySave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={modifyForm} onFinish={onModifyFinish} layout="vertical">
          <Form.Item name="id" label="编号" hidden>
            <Input placeholder="输入编号"/>
          </Form.Item>
          <Form.Item name="companyType" label="单位类型" rules={[{required: true, message: "选择单位类型"}]}>
            <Select options={companyTypes} placeholder="选择单位类型"/>
          </Form.Item>
          <Form.Item name="parent" label="选择上级单位" rules={[{required: true, message: "选择上级单位"}]}>
            <AutoComplete
              options={parentOptions}
              onSearch={(text) => getParentValues(text)}
              placeholder="选择上级单位"/>
          </Form.Item>
          <Form.Item name="name" label="名称-<不能重复>" rules={[{required: true, message: "输入名称"}]}>
            <Input placeholder="输入名称"/>
          </Form.Item>
          <Form.Item name="address" label="地址" rules={[{required: true, message: "输入地址"}]}>
            <Input placeholder="输入地址"/>
          </Form.Item>
          <Form.Item name="merchantName" label="单位名称" rules={[{required: true, message: "输入单位名称"}]}>
            <Input placeholder="输入单位名称"/>
          </Form.Item>
          <Form.Item name="regNum" label="执照编号" rules={[{required: true, message: "输入执照编号"}]}>
            <Input placeholder="输入执照编号"/>
          </Form.Item>
          <Form.Item name="linkman" label="联系人" rules={[{required: true, message: "输入联系人"}]}>
            <Input placeholder="输入联系人"/>
          </Form.Item>
          <Form.Item name="mobile" label="手机号码" rules={[{required: true, message: "输入手机号码"}]}>
            <Input placeholder="输入手机号码"/>
          </Form.Item>
          <Form.Item name="enabled" label="有效状态" rules={[{required: true, message: "选择有效状态"}]}
                     valuePropName="checked">
            <Switch checkedChildren="有效" unCheckedChildren="无效"/>
          </Form.Item>
          <Form.Item name="expireDate" label="有效期" rules={[{required: true, message: "输入有效期"}]}>
            <DatePicker placeholder="选择有效期" format="YYYY-MM-DD"/>
          </Form.Item>
          <Form.Item name="itemTypes" label="运营项目">
            <Checkbox.Group options={itemTypeOptions}/>
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default CompanyTable

