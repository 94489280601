import React, {useEffect, useState} from "react"
import {Button, DatePicker, Descriptions, Drawer, Form, Input, Layout, message, Space, Switch, Table, Tag} from "antd"
import type {ColumnsType} from "antd/es/table"
import {useDispatch, useSelector} from "react-redux"
import dayjs from "dayjs";

const {Search} = Input

/**
 * 定义examinerType
 * @constructor
 */
interface ExaminerType {
  id: number
  idCardCopyKey: string
  idCardNationalKey: string
  idCardName: string
  idCardNumber: string
  idCardAddress: string
  cardPeriodBegin: string
  cardPeriodEnd: string
  mobile: string
  enabled: boolean
  expireDate: string
  memo: string
  modified: string
  created: string
}

const ExaminerTable: React.FC = () => {
  const [infoOpen, setInfoOpen] = useState(false)
  const [info, setInfo] = useState<ExaminerType>({} as ExaminerType)

  const [newOpen, setNewOpen] = useState(false)
  const [newForm] = Form.useForm()

  const [modifyOpen, setModifyOpen] = useState(false)
  const [modifyForm] = Form.useForm()

  const examiner = useSelector((state: any) => state.examiner)
  const dispatch = useDispatch()

  const [messageApi, contextMessageHolder] = message.useMessage()

  const columns: ColumnsType<ExaminerType> = [
    {
      title: "身份证姓名",
      dataIndex: "idCardName",
      key: "idCardName",
    },
    {
      title: "状态",
      dataIndex: "enabled",
      key: "enabled",
      render: (text: any, record: any) => {
        return text ? <Tag color="green">正常</Tag> : <Tag color="red">无效</Tag>
      }
    },
    {
      title: "身份证号码",
      dataIndex: "idCardNumber",
      key: "idCardNumber",
    },
    {
      title: "身份证地址",
      dataIndex: "idCardAddress",
      key: "idCardAddress",
    },
    {
      title: "到期日期",
      dataIndex: "expireDate",
      key: "expireDate",
    },
    {
      title: "手机号码",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => {

        return (
          <>
            <Button type="link" size={"small"} onClick={() => handleInfo(record)}>
              查看
            </Button>
            <Button type="link" size="small" onClick={() => handleModify(record)}>
              修改
            </Button>
          </>
        )
      },
    },
  ]

  const onSearch = (value: string) => {
    let request = examiner.request
    request.keyword = value
    request.pageNum = 1
    dispatch.examiner.setData({request})
  }

  const handleModify = (record: any) => {
    setModifyOpen(true)
    modifyForm.setFieldsValue({...record, expireDate: dayjs(record.expireDate), enabled: (record.enabled) ? 1 : 0})
  }

  const handleInfo = (record: any) => {
    setInfo({...record});
    setInfoOpen(true)
  }

  const onNewClose = () => {
    setNewOpen(false)
  }

  const onNewSave = () => {
    newForm.submit()
  }

  const onNewFinish = (values: any) => {
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.examiner.add(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("新增成功")
        dispatch.examiner.page()
        setNewOpen(false)
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  const onModifyClose = () => {
    setModifyOpen(false)
  }

  const onModifySave = () => {
    modifyForm.submit()
  }

  const onModifyFinish = (values: any) => {
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.examiner.modify(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("修改成功")
        setModifyOpen(false)
        dispatch.examiner.page()
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  useEffect(() => {
    dispatch.examiner.page()
  }, [examiner.request.keyword, examiner.request.pageNum, dispatch.examiner])

  // @ts-ignore
  return (
    <>
      {contextMessageHolder}
      <Layout>
        <Space>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
          <Button type="primary" onClick={() => setNewOpen(true)} style={{backgroundColor: "#339933"}}>
            新建
          </Button>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={examiner.data.list}
            pagination={{
              pageSize: examiner.data.pageSize, // 每页显示的条数
              total: examiner.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...examiner.request, pageNum: page, pageSize}
                dispatch.examiner.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <Drawer
        title="查看合作单位"
        width={500}
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        bodyStyle={{paddingBottom: 80}}
      >
        <Descriptions title="" column={1}>
          <Descriptions.Item label="编号">{info?.id}</Descriptions.Item>
          <Descriptions.Item label="身份证姓名">{info?.idCardName}</Descriptions.Item>
          <Descriptions.Item label="身份证号">{info?.idCardNumber}</Descriptions.Item>
          <Descriptions.Item label="身份证地址">{info?.idCardAddress}</Descriptions.Item>
          <Descriptions.Item label="身份证有效期">{info?.cardPeriodBegin} ~ {info?.cardPeriodEnd}</Descriptions.Item>
          <Descriptions.Item label="有效状态">{info?.enabled ? '正常' : '无效'}</Descriptions.Item>
          <Descriptions.Item label="到期日期">{info?.expireDate}</Descriptions.Item>
          <Descriptions.Item label="手机号码">{info?.mobile}</Descriptions.Item>
          <Descriptions.Item label="备注">{info?.memo}</Descriptions.Item>
          <Descriptions.Item label="新增时间">{info?.created}</Descriptions.Item>
        </Descriptions>
      </Drawer>

      <Drawer
        title="新增考官"
        width={500}
        onClose={onNewClose}
        open={newOpen}
        bodyStyle={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onNewClose}>取消</Button>
            <Button onClick={onNewSave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={newForm} onFinish={onNewFinish} layout="vertical">
          <Form.Item name="idCardName" label="身份证姓名" rules={[{required: true, message: "输入身份证姓名"}]}>
            <Input placeholder="输入身份证姓名"/>
          </Form.Item>
          <Form.Item name="idCardNumber" label="身份证号码" rules={[{required: true, message: "输入身份证号码"}]}>
            <Input placeholder="输入身份证号码"/>
          </Form.Item>
          <Form.Item name="idCardAddress" label="身份证地址" rules={[{required: true, message: "输入身份证地址"}]}>
            <Input placeholder="输入身份证地址"/>
          </Form.Item>
          <Form.Item name="mobile" label="手机号码" rules={[{required: true, message: "输入手机号码"}]}>
            <Input placeholder="输入手机号码"/>
          </Form.Item>
          <Form.Item name="expireDate" label="有效期" rules={[{required: true, message: "输入有效期"}]}>
            <DatePicker placeholder="选择有效期" format="YYYY-MM-DD"/>
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        title="修改考官"
        width={500}
        onClose={onModifyClose}
        open={modifyOpen}
        bodyStyle={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onModifyClose}>取消</Button>
            <Button onClick={onModifySave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={modifyForm} onFinish={onModifyFinish} layout="vertical">
          <Form.Item name="id" label="编号" hidden>
            <Input placeholder="输入编号"/>
          </Form.Item>
          <Form.Item name="idCardName" label="身份证姓名" rules={[{required: true, message: "输入身份证姓名"}]}>
            <Input placeholder="输入身份证姓名"/>
          </Form.Item>
          <Form.Item name="idCardNumber" label="身份证号码" rules={[{required: true, message: "输入身份证号码"}]}>
            <Input placeholder="输入身份证号码"/>
          </Form.Item>
          <Form.Item name="idCardAddress" label="身份证地址" rules={[{required: true, message: "输入身份证地址"}]}>
            <Input placeholder="输入身份证地址"/>
          </Form.Item>
          <Form.Item name="mobile" label="手机号码" rules={[{required: true, message: "输入手机号码"}]}>
            <Input placeholder="输入手机号码"/>
          </Form.Item>
          <Form.Item name="enabled" label="有效状态" rules={[{required: true, message: "选择有效状态"}]}
                     valuePropName="checked">
            <Switch checkedChildren="有效" unCheckedChildren="无效"/>
          </Form.Item>
          <Form.Item name="expireDate" label="有效期" rules={[{required: true, message: "输入有效期"}]}>
            <DatePicker placeholder="选择有效期" format="YYYY-MM-DD"/>
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default ExaminerTable

