import React, {useEffect} from "react"
import "./ExamPlan.less"
import {Button, Layout, Space, Table, Tag, Select,message} from "antd"
import {ColumnsType} from "antd/es/table";
import {useDispatch, useSelector} from "react-redux";
import ExamPlanInfo from "./ExamPlanInfo";
import ExamPlanPoster from "./ExamPlanPoster";
import ExamPlanOrder from "./ExamPlanOrder";

/**
 * ExamPlan
 * @constructor
 */

interface DataType {
  key: string
  id: number
  companyName: string
  specialityId: number
  specialityName: string
  auditDate: string//考试日期
  expireDate: number//报名截止日期
  area: string  //考试区域
  address: string//考试地址
  memo: string
  modified: string
  created: string
}

const ExamPlan: React.FC = () => {
  const [messageApi, contextMessageHolder] = message.useMessage()
  const examPlan = useSelector((state: any) => state.examPlan)
  const dispatch = useDispatch()
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "专业",
      dataIndex: "specialityName",
      key: "specialityName",
      render: (text: any, record: any) => {
        return <>{record.itemTypeName} - {text}</>
      }
    },
    {
      title: "考试日期",
      dataIndex: "auditDate",
      key: "auditDate",
    },
    {
      title: "报名截止日期",
      dataIndex: "expireDate",
      key: "expireDate",
    },
    {
      title: "指导单位",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "考点",
      dataIndex: "auditCompanyName",
      key: "auditCompanyName",
      render: (text: any, record: any) => {
        return <Tag color="blue">{text}</Tag>
      }
    },
    {
      title: "报名人数",
      dataIndex: "applyCount",
      key: "applyCount",
    },
    {
      title: "考试人数",
      dataIndex: "examCount",
      key: "examCount",
    },
    {
      title: "合格人数",
      dataIndex: "passCount",
      key: "passCount",
    },
    {
      title: "付款状态",
      dataIndex: "payStatusName",
      key: "payStatusName",
    },
    {
      title: "更新时间",
      dataIndex: "modified",
      key: "modified",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>
    },
  ]

  //读取列表
  useEffect(() => {
    dispatch.examPlan.page()
  }, [dispatch.examPlan, examPlan.request.keyword, examPlan.request.pageNum])

  //设置指导单位列表
  useEffect(() => {
    dispatch.company.list({companyTypes:['PROVINCE','REGION']}).then((res: any) => {
      let companyList = res.data.map((item: any) => {
        return {label: item.name, value: item.id}
      })
      companyList.unshift({label: '所有', value: 0})
      setCompanyList(companyList)
    })
  }, [dispatch.company]);

  const handleInfo = (record: any) => {
    dispatch.examPlan.setData({openInfo: true, info: record})
  }

  const handlePoster = (record: any) => {
    dispatch.examPlan.setData({openPoster: true, info: record})
  }

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
        <Button type="link" size="small" onClick={() => handlePoster(props.record)}>
          报名海报
        </Button>
      </>
    )
  }

  const [companyList, setCompanyList] = React.useState<any[]>([])
  const payStatusList = [
    {label: '所有', value: ''},
    {label: '未付款', value: 'UNPAID'},
    {label: '已付款', value: 'PAID'},
    {label: '付款中', value: 'PAYING'},
  ]
  const setCompanyId = (text: string) => {
    let request = {...examPlan.request, companyId: text}
    dispatch.examPlan.setData({request})
    dispatch.examPlan.page()
  }

  const setPayStatus = (text: string) => {
    let request = {...examPlan.request, payStatus: text}
    dispatch.examPlan.setData({request})
    dispatch.examPlan.page()
  }

  const addOrder = () => {
    //检查一下选中的计划，必须是同一个companyId的，必须是同一个专业的，必须是合格人数大于0的，必须是未付款的
    let selectedRows = examPlan.selectedRows
    if (selectedRows.length === 0) {
      messageApi.error('请选择需要创建订单的计划')
      return
    }
    let companyId = selectedRows[0].companyId
    let specialityId = selectedRows[0].specialityId
    let result = true;
    selectedRows.every((item: any) => {
      if (item.payStatus !== 'UNPAID') {
        messageApi.error('只能选择未付款的计划')
        result = false
        return false
      }
      if (item.passCount === 0) {
        messageApi.error('只能选择合格人数大于0的计划')
        result = false
        return false
      }
      if (item.companyId !== companyId) {
        messageApi.error('只能选择同一个指导单位的计划')
        result = false
        return false
      }
      if (item.specialityId !== specialityId) {
        messageApi.error('只能选择同一个专业的计划')
        result = false
        return false
      }
      return true
    })
    if (result) {
      //把选中的计划id传递给订单页面
      dispatch.examPlan.setData({orderOpen: true, companyId, specialityId})
    }
  }

  return (
    <div className={'ExamPlan'}>
      {contextMessageHolder}
      <Layout>
        <Space>
          <Select style={{width: 120}} onChange={(text: string) => setCompanyId(text)} options={companyList} placeholder="选择指导单位"/>
          <Select style={{width: 120}} onChange={(text: string) => setPayStatus(text)} options={payStatusList} placeholder="选择付款状态"/>
          <Button type="primary" onClick={() => addOrder()} style={{backgroundColor: "#339933"}}>
            创建订单
          </Button>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: examPlan.selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                dispatch.examPlan.setData({selectedRowKeys, selectedRows})
              },
            }}
            rowKey="id"
            columns={columns}
            dataSource={examPlan.data.list}
            pagination={{
              pageSize: examPlan.data.pageSize, // 每页显示的条数
              total: examPlan.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...examPlan.request, pageNum: page, pageSize}
                dispatch.examPlan.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <ExamPlanInfo/>
      <ExamPlanPoster/>
      <ExamPlanOrder messageApi={messageApi}/>
    </div>
  )
}

export default ExamPlan
