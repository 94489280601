/**
 * 全局配置
 */
const global = {
  name: "CCFA协会系统",
  company: "上海焘韵文化传播有限公司",
  appId: "wx64da7439b29bc360",

  //版本
  version: "0.1",

  //环境，通过请求地址自动判断是否开发环境
  env: window.location.href.startsWith("http://localhost") ? "dev" : "pro",

  //接口服务器
  apiServers: [
    {
      env: "dev",
      // url: "/dev-api",  //开发环境下使用代理
      url: "/pro-api"
    },
    {
      env: "pro",
      url: "https://admin.taoyun.art/api/",
    },
  ],

  //获取接口地址
  getApiServer(): string {
    let url: string = ""
    this.apiServers.forEach((item: any): void => {
      if (item.env === this.env) url = item.url
    })
    return url
  },

  //获取令牌
  getSessionKey(): string {
    //dev环境下不需要登录
    if (this.env === "dev") {
      // return "9b813f5f2c5e8778fcc9e08ca456f41f121486d3641046b50068f4fc05c53610"
    }

    let sessionKey: string | null = localStorage.getItem("sessionKey");
    if (sessionKey === 'undefined' || sessionKey == null) {
      return ""
    }

    let expireTime: string | null = localStorage.getItem("expireTime")
    if (expireTime != null && new Date().getTime() < Date.parse(expireTime.replace(/-/g, "/"))) {
      return sessionKey
    }

    return ""
  },

  //保存令牌
  saveSessionKey(sessionKey: string, expireTime: string): void {
    localStorage.setItem("sessionKey", sessionKey)
    localStorage.setItem("expireTime", expireTime)
  },

  //清空令牌
  clearSessionKey(): void {
    localStorage.removeItem("expireTime")
    localStorage.removeItem("sessionKey")
  },

  //上传头部
  getUploadHeaders() {
    // "Content-Type": "multipart/form-data;charset=utf-8",
    return {"Session-Key": global.getSessionKey()}
  },

  //获取文件地址
  getFileOpenUrl(data: any) {
    if (data !== null)
      return this.getApiServer() + '/pub/v1/file/open?fileKey=' + encodeURI(data.fileKey) + '&accessKey=' + data.accessKey
    return ''
  },

  /**
   * 转换成分
   *
   * @param amount
   */
  getPenny(amount: any) {
    return Math.round(amount.toFixed(2) * 100);
  },
}

export default global
