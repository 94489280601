import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const uploadCert: any = createModel<RootModel>()({
  state: {
    list: [],

    openPoster: false,//打开海报界面
    openAdd: false,
    openInfo: false,//打开详情界面
    info: {},//详情数据

    openModify: false,//打开修改界面
    modify: {},//修改的数据

    request: {
      pageNum: 1,
      pageSize: 20,
      keyword: "",
    },
    data: {
      pageSize: 0,
      total: 0,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 上传
     *
     * @param payload
     * @param rootState
     */
    async upload(payload, rootState) {
      let res = await request.post("/admin/v1/uploadCert/upload", {...payload});
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 上传照片
     *
     * @param payload
     * @param rootState
     */
    async uploadPhoto(payload, rootState) {
      let res = await request.post("/admin/v1/uploadCert/uploadPhoto", {...payload});
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },
    
    /**
     * 查询
     *
     * @param payload
     * @param rootState
     */
    async info(payload, rootState) {
      let res = await request.get("/admin/v1/uploadCert/info/" + payload.id);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 分页查询
     * @param payload
     * @param rootState
     */
    async page(payload, rootState) {
      let res = await request.post("/admin/v1/uploadCert/page", rootState.uploadCert.request);
      if (res.code !== 0) {
        console.error(res.msg);
      } else {
        res.data.list.forEach((item: any) => {
          item.key = item.planId
        })
        dispatch.uploadCert.setData({data: res.data})
      }
      return res;
    }
  }),
})