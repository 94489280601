import {RootModel} from "./index";
import {createModel} from "@rematch/core";
import request from "../util/request";

export const qrCodeLogin = createModel<RootModel>()({

  state: {
    url: 'https://wemp.taoyun.art/moduleAllow',
    tip: '',
    code: "",
    status: 'loading',
  },

  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      }
    }
  },

  effects: (dispatch) => ({
    // handle state changes with impure functions.
    /**
     * 获取二维码
     * @param payload
     * @param rootState
     */
    async refresh(payload, rootState) {
      const res = await request.get("/pub/v1/qrCodeLogin/refresh/ADMIN");
      if (res.code !== 0) {
        console.log(res.msg);
      } else {
        dispatch.qrCodeLogin.setData({code: res.data});
      }
      return res;
    },

    /**
     * 检查二维码状态
     * @param payload
     * @param rootState
     */
    async status(payload, rootState) {
      let code:string = rootState.qrCodeLogin.code;
      const res = await request.get("/pub/v1/qrCodeLogin/status/" + code);
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res;
    }
  }),

})