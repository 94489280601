import React, {useEffect} from "react"
import "./Order.less"
import {Button, Layout, Space, Table, message} from "antd"
import {ColumnsType} from "antd/es/table";
import Search from "antd/es/input/Search";
import {useDispatch, useSelector} from "react-redux";
import OrderInfo from "./OrderInfo";
import OrdePay from "./OrderPay";

interface DataType {
  key: string
  id: number
  companyName: string
  totalGrade: number
  totalPrice: number
  auditPrice: number
  auditDiscount: number
  certificatePrice: number
  amount: number
  refund: number
  planIds: string
  orderStatus: string
  orderStatusName: string
  payStatus: string
  payStatusName: string
  memo: string
  modified: string
  created: string
}

/**
 * Order
 * @constructor
 */
const Order: React.FC = () => {
  const [messageApi, contextMessageHolder] = message.useMessage()
  const order = useSelector((state: any) => state.order)
  const dispatch = useDispatch()
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "指导单位",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "总级数",
      dataIndex: "totalGrade",
      key: "totalGrade",
    },
    {
      title: "测评总金额",
      dataIndex: "auditPrice",
      key: "auditPrice",
      render: (text, record) => {
        return <span>¥ {record.auditPrice/100}</span>
      }
    },
    {
      title: "测评总折扣",
      dataIndex: "auditDiscount",
      key: "auditDiscount",
      render: (text, record) => {
        return <span>¥ {record.auditDiscount/100}</span>
      }
    },
    {
      title: "证书总金额",
      dataIndex: "certificatePrice",
      key: "certificatePrice",
      render: (text, record) => {
        return <span>¥ {record.certificatePrice/100}</span>
      }
    },
    {
      title: "总价格",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text, record) => {
        return <span>¥ {record.totalPrice/100}</span>
      }
    },
    {
      title: "付款金额",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => {
        return <span>¥ {record.amount/100}</span>
      }
    },
    {
      title: "退款金额",
      dataIndex: "refund",
      key: "refund",
      render: (text, record) => {
        return <span>¥ {record.refund/100}</span>
      }
    },
    {
      title: "订单状态",
      dataIndex: "orderStatusName",
      key: "orderStatusName",
    },
    {
      title: "支付状态",
      dataIndex: "payStatusName",
      key: "payStatusName",
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "修改时间",
      dataIndex: "modified",
      key: "modified",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>
    },
  ]

  //读取列表
  useEffect(() => {
    dispatch.order.page()
  }, [dispatch.order, order.request.keyword, order.request.pageNum])

  //搜索
  const onSearch = (value: string) => {
    let request = order.request
    request.keyword = value
    request.pageNum = 1
    dispatch.order.setData({request})
  }

  /**
   * 手动付款
   *
   * @param record
   */
  const handlePay = (record: any) => {
    dispatch.order.setData({payOpen: true, info: record})
  }

  const handleInfo = (record: any) => {
    dispatch.order.setData({openInfo: true, info: record})
  }

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
        {
          props.record.payStatus === 'PAYING' &&
          <Button type="link" size="small" onClick={() => handlePay(props.record)}>
            立即付款
          </Button>
        }
      </>
    )
  }

  return (
    <div className={'UploadCert'}>
      {contextMessageHolder}
      <Layout>
        <Space>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={order.data.list}
            pagination={{
              pageSize: order.data.pageSize, // 每页显示的条数
              total: order.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...order.request, pageNum: page, pageSize}
                dispatch.order.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <OrderInfo/>
      <OrdePay messageApi={messageApi}/>
    </div>
  )
}

export default Order
