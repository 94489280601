import React from "react"
import "./Refund.less"

/**
 * Refund
 * @constructor
 */
const Refund: React.FC = () => {

  return (
    <div className="Refund">
      开发中...
    </div>
  )
}

export default Refund
