import React, {useEffect, useState} from "react"
import {
  Button,
  Cascader,
  Descriptions,
  Drawer,
  Form,
  Input,
  Layout,
  message,
  Space,
  Table,
  Tag,
  Select,
  Modal,
  Rate,
  Empty,
} from "antd"
import type {ColumnsType} from "antd/es/table"
import {useDispatch, useSelector} from "react-redux"
import dayjs from "dayjs";
import "../../global";
import "./SchoolTable.less"

const {Search} = Input

/**
 * SchoolVo
 * @constructor
 */
interface CompanyType {
  id: number
  name: string
  address: string
  telephone: string
  lat: number
  lng: number
  area: string
  baiduUid: string
  schoolStatus: string
  memo: string
  modified: string
  created: string
}

const SchoolTable: React.FC = () => {

  const [infoOpen, setInfoOpen] = useState(false)
  const [touchOpen, setTouchOpen] = useState(false)
  const [info, setInfo] = useState<CompanyType>({} as CompanyType)

  const [newOpen, setNewOpen] = useState(false)
  const [newForm] = Form.useForm()

  const [modifyOpen, setModifyOpen] = useState(false)
  const [modifyForm] = Form.useForm()

  const school = useSelector((state: any) => state.school)
  const city = useSelector((state: any) => state.city)
  const dispatch = useDispatch()

  const [messageApi, contextMessageHolder] = message.useMessage()

  const [provinceList, setProvinceList] = useState<any[]>([])
  const [cityList, setCityList] = useState<any[]>([])
  const [areaList, setAreaList] = useState<any[]>([])

  const [touchAdd, setTouchAdd] = useState(false)
  const [touchAddForm] = Form.useForm();

  //学校状态
  const schoolStatus = [
    {value: 'NEW', label: '新的'},
    {value: 'CONNECTING', label: '联系中'},
    {value: 'DELETED', label: '已删除'},
    {value: 'SUCCESSFUL', label: '成功'},
  ]
  const getSchoolStatus = (status: string) => {
    let label = '未知';
    schoolStatus.forEach((item: any) => {
      if (item.value === status) {
        label = item.label;
      }
    });
    return label;
  }

  const columns: ColumnsType<CompanyType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "电话",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "地区",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "状态",
      dataIndex: "schoolStatus",
      key: "schoolStatus",
      render: (text: any, record: any) => {
        let status = getSchoolStatus(text)
        return text === 'DELETED' ? <Tag color="red">{status}</Tag> : <Tag color="green">{status}</Tag>
      }
    },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => {

        return (
          <>
            <Button type="link" size={"small"} onClick={() => handleInfo(record)}>
              查看
            </Button>
            <Button type="link" size="small" onClick={() => handleModify(record)}>
              修改
            </Button>
            {
              record.schoolStatus !== 'DELETED' &&
              <Button type="link" size="small" onClick={() => handleDelete(record)}>
                删除
              </Button>
            }
            <Button type="link" size={"small"} onClick={() => handleTouch(record)}>
              联系
            </Button>
          </>
        )
      },
    },
  ]

  const onSearch = (value: string) => {
    let request = school.request
    request.keyword = value
    request.pageNum = 1
    dispatch.school.setData({request})
    dispatch.school.page()
  }

  const handleModify = (record: any) => {
    setModifyOpen(true)
    modifyForm.setFieldsValue({...record, expireDate: dayjs(record.expireDate), enabled: (record.enabled) ? 1 : 0})
  }

  const handleDelete = (record: any) => {
    dispatch.school.delete({schoolId: record.id}).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("删除成功")
        dispatch.school.page()
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  const handleInfo = (record: any) => {
    setInfo({...record});
    setInfoOpen(true)
  }

  const handleTouch = (record: any) => {
    setInfo({...record})
    setTouchOpen(true)
    dispatch.schoolTouch.list({schoolId: record.id});
    touchAddForm.resetFields();
  }

  const onNewClose = () => {
    setNewOpen(false)
  }

  const onNewSave = () => {
    newForm.submit()
  }

  const onNewFinish = (values: any) => {
    dispatch.school.add(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("新增成功")
        dispatch.school.page()
        setNewOpen(false)
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  const onModifyClose = () => {
    setModifyOpen(false)
  }

  const onModifySave = () => {
    modifyForm.submit()
  }

  const onModifyFinish = (values: any) => {
    dispatch.school.modify(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("修改成功")
        setModifyOpen(false)
        dispatch.school.page()
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  useEffect(() => {
    dispatch.school.page()
    dispatch.city.list()
    dispatch.school.province().then((res: any) => {
      if (res.code === 0) {
        let list = res.data.map((item: any) => {
          return {label: item, value: item}
        })
        setProvinceList(list)
      }
    });
  }, [school.request.keyword, school.request.pageNum, dispatch.school, dispatch.city])

  useEffect(() => {
    dispatch.school.city().then((res: any) => {
      if (res.code === 0) {
        let list = res.data.map((item: any) => {
          return {label: item, value: item}
        })
        setCityList(list)
      }
    });
  }, [school.request.province, dispatch.school]);

  useEffect(() => {
    dispatch.school.area().then((res: any) => {
      if (res.code === 0) {
        let list = res.data.map((item: any) => {
          return {label: item, value: item}
        })
        setAreaList(list)
      }
    });
  }, [school.request.city, dispatch.school]);

  const setSchoolStatus = (text: any) => {
    let request = {...school.request, schoolStatus: text}
    dispatch.school.setData({request})
  }

  const setProvince = (text: any) => {
    let request = {...school.request, province: text}
    dispatch.school.setData({request})
  }

  const setCity = (text: any) => {
    let request = {...school.request, city: text}
    dispatch.school.setData({request})
  }

  const setArea = (text: any) => {
    let request = {...school.request, area: text}
    dispatch.school.setData({request})
  }

  const onTouchClose = () => {
    setTouchOpen(false)
  }

  const handleTouchAddCancel = () => {
    setTouchAdd(false)
  }

  const handleTouchAddOk = () => {
    touchAddForm.submit();
  }

  const onTouchAdd = () => {
    setTouchAdd(true)
  }

  const onTouchAddFinish = (values: any) => {
    dispatch.schoolTouch.add({...values, schoolId: info.id}).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("新增成功")
        setTouchAdd(false)
        dispatch.schoolTouch.list({schoolId: info.id});
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  const schoolTouch = useSelector((state: any) => state.schoolTouch)

  // @ts-ignore
  return (
    <div className={'SchoolTable'}>
      {contextMessageHolder}
      <Modal title="新增联系" open={touchAdd} onOk={handleTouchAddOk} onCancel={handleTouchAddCancel}>
        <Form form={touchAddForm} onFinish={onTouchAddFinish} layout="vertical">
          <Form.Item name="progress" label="进度" rules={[{required: true, message: "选择进度"}]}>
            <Rate count={10} style={{color: 'green'}}/>
          </Form.Item>
          <Form.Item name="content" label="内容" rules={[{required: true},]}>
            <Input.TextArea rows={4} placeholder="输入内容"/>
          </Form.Item>
        </Form>
      </Modal>

      <Layout>
        <Space>
          <Select style={{width: 100}} onChange={(text: string) => setSchoolStatus(text)} options={schoolStatus} placeholder="选择状态" defaultValue={school.request.schoolStatus}/>
          <Select style={{width: 120}} onChange={(text: string) => setProvince(text)} options={provinceList} placeholder="选择省份" defaultValue={school.request.province}/>
          <Select style={{width: 120}} onChange={(text: string) => setCity(text)} options={cityList} placeholder="选择城市" defaultValue={school.request.city}/>
          <Select style={{width: 120}} onChange={(text: string) => setArea(text)} options={areaList} placeholder="选择县区" defaultValue={school.request.area}/>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch} defaultValue={school.request.keyword}/>
          <Button type="primary" onClick={() => setNewOpen(true)} style={{backgroundColor: "#339933"}}>
            新建
          </Button>
          当前总数量：{school.data.total}，当前总页数：{school.data.pages}
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={school.data.list}
            pagination={{
              pageSize: school.data.pageSize, // 每页显示的条数
              total: school.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...school.request, pageNum: page, pageSize}
                dispatch.school.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <Drawer
        title="查看机构"
        width={500}
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        bodyStyle={{paddingBottom: 80}}
      >
        <Descriptions title="" column={1}>
          <Descriptions.Item label="编号">{info?.id}</Descriptions.Item>
          <Descriptions.Item label="名称">{info?.name}</Descriptions.Item>
          <Descriptions.Item label="地址">{info?.address}</Descriptions.Item>
          <Descriptions.Item label="电话">{info?.telephone}</Descriptions.Item>
          <Descriptions.Item label="地区">{info?.area}</Descriptions.Item>
          <Descriptions.Item label="百度uid">{info?.baiduUid}</Descriptions.Item>
          <Descriptions.Item label="状态">{getSchoolStatus(info?.schoolStatus)}</Descriptions.Item>
          <Descriptions.Item label="备注">{info?.memo}</Descriptions.Item>
          <Descriptions.Item label="创建时间">{info?.created}</Descriptions.Item>
        </Descriptions>
      </Drawer>

      <Drawer
        title="新增机构"
        width={500}
        onClose={onNewClose}
        open={newOpen}
        bodyStyle={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onNewClose}>取消</Button>
            <Button onClick={onNewSave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={newForm} onFinish={onNewFinish} layout="vertical">
          <Form.Item name="name" label="名称-<同一地区不能重复>" rules={[{required: true, message: "输入名称"}]}>
            <Input placeholder="输入名称"/>
          </Form.Item>
          <Form.Item name="address" label="地址" rules={[{required: true, message: "输入地址"}]}>
            <Input placeholder="输入地址"/>
          </Form.Item>
          <Form.Item name="telephone" label="联系电话" rules={[{required: true, message: "输入联系电话"}]}>
            <Input placeholder="输入联系电话"/>
          </Form.Item>
          <Form.Item
            name="area"
            label="地区"
            rules={[{required: true, message: "选择地区"}]}
            getValueFromEvent={(e) => {
              return e.join('/')
            }}>
            <Cascader options={city.list} placeholder="选择地区"/>
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        title="修改学校"
        width={500}
        onClose={onModifyClose}
        open={modifyOpen}
        bodyStyle={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onModifyClose}>取消</Button>
            <Button onClick={onModifySave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={modifyForm} onFinish={onModifyFinish} layout="vertical">
          <Form.Item name="id" label="编号" hidden>
            <Input placeholder="输入编号"/>
          </Form.Item>
          <Form.Item name="name" label="名称-<同地区不能重复>" rules={[{required: true, message: "输入名称"}]}>
            <Input placeholder="输入名称"/>
          </Form.Item>
          <Form.Item name="address" label="地址" rules={[{required: true, message: "输入地址"}]}>
            <Input placeholder="输入地址"/>
          </Form.Item>
          <Form.Item name="telephone" label="联系电话" rules={[{required: true, message: "输入联系电话"}]}>
            <Input placeholder="输入联系电话"/>
          </Form.Item>
          <Form.Item name="schoolStatus" label="状态" rules={[{required: true, message: "选择状态"}]}>
            <Select options={schoolStatus} placeholder="选择状态"/>
          </Form.Item>
          <Form.Item name="area" label="地区" rules={[{required: true, message: "选择地区"}]}
                     getValueFromEvent={(e) => {
                       return e.join('/')
                     }}>
            <Cascader options={city.list} placeholder="选择地区"/>
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        title="联系"
        width={500}
        onClose={onTouchClose}
        open={touchOpen}
        bodyStyle={{paddingBottom: 80}}
        extra={
          <Space>
            <Button onClick={onTouchClose}>取消</Button>
            <Button onClick={onTouchAdd} type="primary">
              新增
            </Button>
          </Space>
        }
      >
        <div>
        {
          schoolTouch.list.length === 0 && <Empty />
        }
        {
          schoolTouch.list.map((item: any, index: number) => {
            return (
              <div style={{marginBottom: 10, padding: 10,fontSize: 12,backgroundColor: '#CCC',borderRadius: 5}} key={index} >
                <div style={{height: 20}}><Rate style={{color: 'green',fontSize: 12}} disabled defaultValue={item.progress} /><span className="ant-rate-text">{item.progress}0%</span></div>
                <div style={{minHeight: 20}}>{item.content}</div>
                <div style={{height: 20, textAlign: 'right'}}>{item.created}</div>
              </div>
            )
          })
        }
        </div>
      </Drawer>
    </div>
  )
}

export default SchoolTable

