import {Descriptions, Drawer, QRCode} from "antd";
import {useDispatch, useSelector} from "react-redux";

const ExamPlanInfo = () => {
  const examPlan = useSelector((state: any) => state.examPlan)
  const dispatch = useDispatch()

  return (
    <Drawer
      title="查看计划详情"
      width={500}
      open={examPlan.openInfo}
      onClose={() => dispatch.examPlan.setData({openInfo: false})}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="编号">{examPlan.info?.id}</Descriptions.Item>
        <Descriptions.Item label="考试专业">{examPlan.info?.specialityName}</Descriptions.Item>
        <Descriptions.Item label="考试日期">{examPlan.info?.auditDate}</Descriptions.Item>
        <Descriptions.Item label="报名截止日期">{examPlan.info?.expireDate}</Descriptions.Item>
        <Descriptions.Item label="考点单位">{examPlan.info?.companyName}</Descriptions.Item>
        <Descriptions.Item label="考试地区">{examPlan.info?.area}</Descriptions.Item>
        <Descriptions.Item label="考试地址">{examPlan.info?.address}</Descriptions.Item>
        <Descriptions.Item label="备注">{examPlan.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="修改时间">{examPlan.info?.modified}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{examPlan.info?.created}</Descriptions.Item>
        <Descriptions.Item label="报名二维码">
          <QRCode value={'https://wemp.taoyun.art/newExamApply?planId=' + examPlan.info?.id} />
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default ExamPlanInfo