import {Models} from "@rematch/core"
import {qrCodeLogin} from "./qrCodeLogin"
import {session} from "./session"
import {userModule} from "./userModule"
import {test} from "./test"
import {file} from "./file"
import {company} from "./company"
import {examiner} from "./examiner"
import {school} from "./school"
import {schoolTouch} from "./schoolTouch"
import {city} from "./city"
import {uploadCert} from "./uploadCert"
import {examPlan} from "./examPlan"
import {order} from "./order"
import {pay} from "./pay"
import {itemType} from "./itemType"

export interface RootModel extends Models<RootModel> {
  city: typeof city
  company: typeof company
  examiner: typeof examiner
  examPlan: typeof examPlan
  file: typeof file
  itemType: typeof itemType
  order: typeof order
  pay: typeof pay
  qrCodeLogin: typeof qrCodeLogin
  school: typeof school
  schoolTouch: typeof schoolTouch
  session: typeof session
  test: typeof test
  uploadCert: typeof uploadCert
  userModule: typeof userModule
}

export const models: RootModel = {
  city,
  company,
  examiner,
  examPlan,
  file,
  itemType,
  order,
  pay,
  qrCodeLogin,
  school,
  schoolTouch,
  session,
  test,
  uploadCert,
  userModule,
}
