import React, {useEffect} from "react"
import "./Pay.less"
import {Button, Layout, Space, Table} from "antd"
import {ColumnsType} from "antd/es/table";
import Search from "antd/es/input/Search";
import {useDispatch, useSelector} from "react-redux";
import PayInfo from "./PayInfo";

interface DataType {
  id: number;
  orderId: number;
  companyName: string;
  payNo: string;
  payType: string;
  payTypeName: string;
  payId: string;
  amount: number;
  payTime: string;
  memo: string;
  modified: string;
  created: string;
}

/**
 * Pay
 * @constructor
 */
const Pay: React.FC = () => {
  const pay = useSelector((state: any) => state.pay)
  const dispatch = useDispatch()
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "订单编号",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "指导单位",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "支付单号",
      dataIndex: "payNo",
      key: "payNo",
    },
    {
      title: "支付方式",
      dataIndex: "payTypeName",
      key: "payTypeName",
    },
    {
      title: "支付金额",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => {
        return <span>¥ {record.amount/100}</span>
      }
    },
    {
      title: "支付时间",
      dataIndex: "payTime",
      key: "payTime",
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "修改时间",
      dataIndex: "modified",
      key: "modified",
    },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>
    },
  ]

  //读取列表
  useEffect(() => {
    dispatch.pay.page()
  }, [dispatch.pay, pay.request.keyword, pay.request.pageNum])

  //搜索
  const onSearch = (value: string) => {
    let request = pay.request
    request.keyword = value
    request.pageNum = 1
    dispatch.pay.setData({request})
  }

  const handleInfo = (record: any) => {
    dispatch.pay.setData({openInfo: true, info: record})
  }

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
      </>
    )
  }

  return (
    <div className={'Pay'}>
      <Layout>
        <Space>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={pay.data.list}
            pagination={{
              pageSize: pay.data.pageSize, // 每页显示的条数
              total: pay.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...pay.request, pageNum: page, pageSize}
                dispatch.pay.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <PayInfo/>
    </div>
  )
}

export default Pay
