import React from 'react'
import './School.less'
import SchoolTable from './SchoolTable'

const School: React.FC = () => {
  return (
    <div className="School">
      <SchoolTable/>
    </div>
  )
}

export default School