import {Descriptions, Drawer, QRCode} from "antd";
import {useDispatch, useSelector} from "react-redux";

const UploadCertInfo = () => {
  const uploadCert = useSelector((state: any) => state.uploadCert)
  const dispatch = useDispatch()

  return (
    <Drawer
      title="查看计划详情"
      width={500}
      open={uploadCert.openInfo}
      onClose={() => dispatch.uploadCert.setData({openInfo: false})}
      bodyStyle={{paddingBottom: 80}}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="计划编号">{uploadCert.info?.planId}</Descriptions.Item>
        <Descriptions.Item label="批次编号">{uploadCert.info?.batchId}</Descriptions.Item>
        <Descriptions.Item label="协会编号">{uploadCert.info?.outId}</Descriptions.Item>
        <Descriptions.Item label="考试专业">{uploadCert.info?.specialityName}</Descriptions.Item>
        <Descriptions.Item label="考试日期">{uploadCert.info?.auditDate}</Descriptions.Item>
        <Descriptions.Item label="报名截止日期">{uploadCert.info?.expireDate}</Descriptions.Item>
        <Descriptions.Item label="考点单位">{uploadCert.info?.companyName}</Descriptions.Item>
        <Descriptions.Item label="考试地区">{uploadCert.info?.area}</Descriptions.Item>
        <Descriptions.Item label="考试地址">{uploadCert.info?.address}</Descriptions.Item>
        <Descriptions.Item label="备注">{uploadCert.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="修改时间">{uploadCert.info?.modified}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{uploadCert.info?.created}</Descriptions.Item>
        <Descriptions.Item label="报名二维码">
          <QRCode value={'https://wemp.taoyun.art/newExamApply?planId=' + uploadCert.info?.id} />
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default UploadCertInfo