import React from "react"
import "./Examiner.less"
import ExaminerTable from "./ExaminerTable"

/**
 * Examiner
 * @constructor
 */
const Examiner: React.FC = () => {

  return (
    <div className="Examiner">
      <ExaminerTable />
    </div>
  )
}

export default Examiner
