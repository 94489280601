import React, {useEffect} from "react"
import {Space, Drawer, Button, Form, Input, Descriptions} from "antd";
import {useDispatch, useSelector} from "react-redux";

const ExamPlanOrder = (props: {messageApi: any }) => {
  const dispatch = useDispatch();
  const examPlan = useSelector((state: any) => state.examPlan)
  const newForm = Form.useForm()[0]
  const [pre, setPre] = React.useState<any>(null)

  const onNewClose = () => {
    dispatch.examPlan.setData({orderOpen: false})
  }

  const onNewSave = () => {
    newForm.submit()
  }

  const onNewFinish = () => {
    //提交接口
    let data = {
      companyId: examPlan.companyId,
      specialityId: examPlan.specialityId,
      planIds: examPlan.selectedRows.map((item: any) => item.id),
      memo: newForm.getFieldValue('memo')
    }
    //提交订单
    dispatch.order.add(data).then((res: any) => {
      if (res.code !== 0) {
        props.messageApi.error(res.msg)
        return;
      }
      props.messageApi.success('创建订单成功')
      dispatch.examPlan.setData({selectedRows: [], selectedRowKeys: []})
      dispatch.examPlan.page()
      dispatch.examPlan.setData({orderOpen: false})
    })
  }

  useEffect(() => {
    if (examPlan.orderOpen) {
      let data = {
        companyId: examPlan.companyId,
        specialityId: examPlan.specialityId,
        planIds: examPlan.selectedRows.map((item: any) => item.id)
      }
      dispatch.order.pre(data).then((res: any) => {
        if (res.code === 0) {
          setPre(res.data)
        }
      })
    }
  }, [examPlan.orderOpen, dispatch.order, examPlan.companyId, examPlan.specialityId, examPlan.selectedRows, newForm])

  return (
      <Drawer
        title="手动下单"
        width={500}
        onClose={onNewClose}
        open={examPlan.orderOpen}
        extra={
          <Space>
            <Button onClick={onNewClose}>取消</Button>
            <Button onClick={onNewSave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Descriptions title="" column={1}>
          <Descriptions.Item label="指导单位">{pre?.companyName}（优惠折扣：{pre?.companyDiscount}%）</Descriptions.Item>
          <Descriptions.Item label="专业">{pre?.itemTypeName}-{pre?.specialityName}</Descriptions.Item>
          <Descriptions.Item label="合格总级数">{pre?.totalGrade}人次</Descriptions.Item>
          <Descriptions.Item label="测评总金额">¥ {pre?.auditPrice/100}</Descriptions.Item>
          <Descriptions.Item label="测评总折扣">¥ {pre?.auditDiscount/100}</Descriptions.Item>
          <Descriptions.Item label="证书总金额">¥ {pre?.certificatePrice/100}</Descriptions.Item>
          <Descriptions.Item label="实付金额（测评总金额-测评优惠金额+证书总金额）">¥ {pre?.amount/100}</Descriptions.Item>
        </Descriptions>

        <Form form={newForm} onFinish={onNewFinish} layout="vertical" initialValues={{memo: '协会手动下单'}}>
          <Form.Item name="memo" label="备注" rules={[{required: false},]}>
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>

      </Drawer>
  )
}

export default ExamPlanOrder