import React from "react"
import "./Apply.less"

/**
 * apply
 * @constructor
 */
const Apply: React.FC = () => {

  return (
    <div className="Apply">
      开发中...
    </div>
  )
}

export default Apply
