import React, {lazy, Suspense, useEffect} from "react"
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom"
import Apply from "./pages/apply/Apply"
import Company from "./pages/company/Company"
import Exam from "./pages/exam/Exam"
import Examiner from "./pages/examiner/Examiner"
import ExamPlan from "./pages/examPlan/ExamPlan"
import Order from "./pages/order/Order"
import Pay from "./pages/pay/Pay"
import Refund from "./pages/refund/Refund"
import Login from "./pages/login/Login"
import Test from "./pages/test/Test"
import Error from "./pages/error/Error"
import Dashboard from "./pages/dashboard/Dashboard"
import UserModule from "./pages/userModule/UserModule"
import UploadCert from "./pages/uploadCert/UploadCert"
import {useSelector} from "react-redux"
import {RootState} from "./store"
import School from "./pages/school/School"

const Home = lazy(() => import("./pages/home/Home"))

/**
 * 应用路由
 */
const AppRouter = () => {
  let session = useSelector((state: RootState) => state.session)

  useEffect(() => {
    document.title = "焘韵文化";
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home/dashboard"/>}/>
        <Route path="/test" element={<Test/>}/>
        <Route path="/error" element={<Error/>}/>
        <Route path="/login" element={<Login/>}/>

        {/* 后台 */}
        <Route
          path="/home"
          element={
            session.isLogin ? (
              <Suspense>
                <Home/>
              </Suspense>
            ) : (
              <Navigate to="/login"/>
            )
          }
        >
          <Route path="/home/apply" element={<Apply/>}/>
          <Route path="/home/company" element={<Company/>}/>
          <Route path="/home/dashboard" element={<Dashboard/>}/>
          <Route path="/home/exam" element={<Exam/>}/>
          <Route path="/home/examiner" element={<Examiner/>}/>
          <Route path="/home/examPlan" element={<ExamPlan/>}/>
          <Route path="/home/order" element={<Order/>}/>
          <Route path="/home/pay" element={<Pay/>}/>
          <Route path="/home/refund" element={<Refund/>}/>
          <Route path="/home/userModule" element={<UserModule/>}/>
          <Route path="/home/school" element={<School/>}/>
          <Route path="/home/uploadCert" element={<UploadCert/>}/>
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRouter
