import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const order:any = createModel<RootModel>()({
  state: {
    list: [],
    request: {
      pageNum: 1,
      pageSize: 20,
      keyword: "",
    },
    data: {
      pageSize: 0,
      total: 0,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 预先计算订单
     *
     * @param payload
     * @param rootState
     */
    async pre(payload, rootState) {
      let res = await request.post("/admin/v1/order/pre", payload);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },
    /**
     * 新增订单
     *
     * @param payload
     * @param rootState
     * @returns {Promise<*>}
     */
    async add(payload, rootState) {
      let res = await request.post("/admin/v1/order", payload)
      if (res.code > 0) {
        console.log(res.msg)
      }
      return res;
    },

    /**
     * 详情
     *
     * @param payload
     * @param rootState
     */
    async info(payload, rootState) {
      let res = await request.get("/admin/v1/order/info/" + payload.id);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 分页查询
     * @param payload
     * @param rootState
     */
    async page(payload, rootState) {
      let res = await request.post("/admin/v1/order/page", rootState.order.request);
      if (res.code !== 0) {
        console.error(res.msg);
      } else {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.order.setData({data: res.data})
      }
      return res;
    }
  }),
})