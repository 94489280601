import React from "react"
import "./Dashboard.less"
import {Layout} from "antd"

/**
 * 仪表盘
 * @constructor
 */
const Dashboard: React.FC = () => {

  return (
    <Layout className="Dashboard">
      开发中...
    </Layout>
  )
}

export default Dashboard
