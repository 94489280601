import React, {useEffect} from "react"
import "./UploadCert.less"
import {Button, Layout, Space, Table, Tag, message} from "antd"
import {ColumnsType} from "antd/es/table";
import Search from "antd/es/input/Search";
import {useDispatch, useSelector} from "react-redux";
import UploadCertInfo from "./UploadCertInfo";

interface DataType {
  key: string
  id: number
  planId: number
  batchId: string
  outId: string
  companyName: string
  specialityId: number
  specialityName: string
  auditDate: string//考试日期
  expireDate: number//报名截止日期
  area: string  //考试区域
  address: string//考试地址
  memo: string
  modified: string
  created: string
  applyCount: number
  examCount: number
  payCount: number
  uploadCount: number
  auditCompanyName: string
}

const UploadCert: React.FC = () => {
  const [messageApi, contextMessageHolder] = message.useMessage()
  const uploadCert = useSelector((state: any) => state.uploadCert)
  const dispatch = useDispatch()
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "planId",
      key: "planId",
    },
    {
      title: "专业",
      dataIndex: "specialityName",
      key: "specialityName",
      render: (text: any, record: any) => {
        return <>{record.itemTypeName}-{text}</>
      }
    },
    {
      title: "考试日期",
      dataIndex: "auditDate",
      key: "auditDate",
    },
    {
      title: "报名截止日期",
      dataIndex: "expireDate",
      key: "expireDate",
    },
    {
      title: "指导单位",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "考点",
      dataIndex: "auditCompanyName",
      key: "auditCompanyName",
      render: (text: any, record: any) => {
        return <Tag color="blue">{text}</Tag>
      }
    },
    {
      title: "报名人数",
      dataIndex: "applyCount",
      key: "applyCount",
    },
    {
      title: "考试人数",
      dataIndex: "examCount",
      key: "examCount",
    },
    {
      title: "缴费人数",
      dataIndex: "payCount",
      key: "payCount",
    },
    {
      title: "上传人数",
      dataIndex: "uploadCount",
      key: "uploadCount",
    },
    {
      title: "上传批次",
      dataIndex: "batchId",
      key: "batchId",
      render: (text: any, record: any) => {
        return <>{text === '' && record.payCount>0 ? (
          <Button type={"primary"} size="small" onClick={() => handleUpload(record)}>
          立即上传
        </Button>)
          : text}</>
      }
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>
    },
  ]

  //读取列表
  useEffect(() => {
    dispatch.uploadCert.page()
  }, [dispatch.uploadCert, uploadCert.request.keyword, uploadCert.request.pageNum])

  //搜索
  const onSearch = (value: string) => {
    let request = uploadCert.request
    request.keyword = value
    request.pageNum = 1
    dispatch.uploadCert.setData({request})
  }

  /**
   * 上传到文促会
   *
   * @param record
   */
  const handleUpload = (record: DataType) => {
    // console.log(record)
    dispatch.uploadCert.upload({planId: record.planId}).then((res:any) => {
      if (res.code !== 0) {
        messageApi.error(res.msg)
        return
      }
      messageApi.success('任务发送成功')
      dispatch.uploadCert.page()
    })
  }

  /**
   * 上传照片
   *
   * @param record
   */
  const handleUploadPhoto = (record: DataType) => {
    // console.log(record)
    dispatch.uploadCert.uploadPhoto({batchId: record.batchId}).then((res:any) => {
      if (res.code !== 0) {
        messageApi.error(res.msg)
        return
      }
      messageApi.success('任务发送成功')
      dispatch.uploadCert.page()
    })
  }

  const handleInfo = (record: any) => {
    dispatch.uploadCert.setData({openInfo: true, info: record})
  }

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
        {
          props.record.batchId !== '' && (
            <Button type="link" size="small" onClick={() => handleUploadPhoto(props.record)}>
              重新上传证书信息
            </Button>
          )

        }
      </>
    )
  }

  return (
    <div className={'UploadCert'}>
      {contextMessageHolder}
      <Layout>
        <Space>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={uploadCert.data.list}
            pagination={{
              pageSize: uploadCert.data.pageSize, // 每页显示的条数
              total: uploadCert.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...uploadCert.request, pageNum: page, pageSize}
                dispatch.uploadCert.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <UploadCertInfo/>
    </div>
  )
}

export default UploadCert
