import {Descriptions, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";

/**
 * private Integer id;
 *   private String companyName;//指导单位
 *   private Integer totalGrade;//总级数
 *   private Integer totalPrice;//总价格
 *   private Integer discount;//折扣金额
 *   private Integer amount;//付款金额
 *   private Integer refund;//退款金额
 *   private String planIds;//计划编号
 *   private OrderStatus orderStatus;//订单状态
 *   private PayStatus payStatus;//支付状态
 *   private String memo;
 *   private Date modified;
 *   private Date created;
 * @constructor
 */
const OrderInfo = () => {
  const order = useSelector((state: any) => state.order)
  const dispatch = useDispatch()

  return (
    <Drawer
      title="查看订单详情"
      width={500}
      open={order.openInfo}
      onClose={() => dispatch.order.setData({openInfo: false})}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="编号">{order.info?.id}</Descriptions.Item>
        <Descriptions.Item label="指导单位">{order.info?.companyName}</Descriptions.Item>
        
        <Descriptions.Item label="总级数">{order.info?.totalGrade}</Descriptions.Item>
        <Descriptions.Item label="测评总金额">¥ {order.info?.auditPrice/100}</Descriptions.Item>
        <Descriptions.Item label="优惠总金额">¥ {order.info?.auditDiscount/100}</Descriptions.Item>
        <Descriptions.Item label="证书总金额">¥ {order.info?.certificatePrice/100}</Descriptions.Item>
        <Descriptions.Item label="总价格">¥ {order.info?.totalPrice/100}</Descriptions.Item>
        <Descriptions.Item label="付款金额">¥ {order.info?.amount/100}</Descriptions.Item>
        <Descriptions.Item label="退款金额">¥ {order.info?.refund/100}</Descriptions.Item>

        <Descriptions.Item label="计划编号">{order.info?.planIds}</Descriptions.Item>
        <Descriptions.Item label="订单状态">{order.info?.orderStatusName}</Descriptions.Item>
        <Descriptions.Item label="支付状态">{order.info?.payStatusName}</Descriptions.Item>
        <Descriptions.Item label="备注">{order.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{order.info?.created}</Descriptions.Item>
        <Descriptions.Item label="修改时间">{order.info?.modified}</Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default OrderInfo