import React from "react"
import "./Exam.less"

/**
 * exam
 * @constructor
 */
const Exam: React.FC = () => {

  return (
    <div className="Exam">
      开发中...
    </div>
  )
}

export default Exam
