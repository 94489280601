import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

/**
 * 机构
 */
export const school = createModel<RootModel>()({
  state: {
    info: {},
    request: {
      keyword: "",
      pageNum: 1,
      pageSize: 20,
      province: '',
      city: '',
      area: '',
      schoolStatus: 'NEW',
    },
    data: {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      list: [],
    },
  },
  reducers: {
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    /**
     * 分页显示
     *
     * @param payload
     * @param state
     * @returns
     */
    async page(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/school/page", state.school.request)
      if (res.code === 0) {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.school.setData({data: res.data})
      }
      return res
    },

    /**
     * 添加
     *
     * @param payload
     * @param state
     * @returns
     */
    async add(payload, state): Promise<any> {
      let res = await request.post("/admin/v1/school", payload)
      return res
    },

    /**
     * 修改
     *
     * @param payload
     * @param state
     * @returns
     */
    async modify(payload, state): Promise<any> {
      let res = await request.put("/admin/v1/school", payload)
      return res
    },

    /**
     * 删除
     *
     * @param payload
     * @param state
     * @returns
     */
    async delete(payload, state): Promise<any> {
      let res = await request.delete("/admin/v1/school/" + payload.schoolId)
      return res
    },

    /**
     * 查看详情
     *
     * @param payload
     * @param state
     * @returns
     */
    async detail(payload, state): Promise<any> {
      let res = await request.get("/admin/v1/school/" + payload.schoolId)
      if (res.code === 0) {
        dispatch.school.setData({info: res.data})
      }
      return res
    },

    /**
     * 查询所有省份
     *
     * @param payload
     * @param state
     * @returns
     */
    async province(payload, state): Promise<any> {
      let res = await request.get("/admin/v1/school/province")
      return res;
    },

    /**
     * 查询所有城市
     *
     * @param payload
     * @param state
     * @returns
     */
    async city(payload, state): Promise<any> {
      let province = state.school.request.province;
      let res = await request.get("/admin/v1/school/city?province=" + province)
      return res;
    },

    /**
     * 查询所有区县
     *
     * @param payload
     * @param state
     * @returns
     */
    async area(payload, state): Promise<any> {
      let province = state.school.request.province;
      let city = state.school.request.city;
      let res = await request.get("/admin/v1/school/area?province=" + province + "&city=" + city)
      return res;
    },
  }),
});